import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import ButtonMemoPrev from './ButtonMemoPrev';
import ButtonMemoNext from './ButtonMemoNext';

function MemoQuestion({ index, setIndex }) {
  const [indexMemo, setIndexMemo] = useState([]);

  const onClickMemo = useCallback(
    (e) => {
      const checked = e.target.checked;
      // set unique index memo with order ascending
      if (checked) {
        setIndexMemo((iMemo) => [...new Set([...iMemo, index])].sort((a, b) => a - b));
      } else {
        setIndexMemo((iMemo) => iMemo.filter((i) => i !== index));
      }
    },
    [setIndexMemo, index]
  );

  const checked = useMemo(() => indexMemo.includes(index), [indexMemo, index]);

  return (
    <Box sx={{ display: 'flex', columnGap: 5, alignItems: 'center' }}>
      <ButtonMemoPrev disabled={indexMemo.length === 0 || index <= indexMemo[0]} index={index} setIndex={setIndex} indexMemo={indexMemo} />
      <FormControlLabel
        sx={{
          flexDirection: 'column-reverse',
          alignItems: 'center',
          margin: 0,
          textAlign: 'center',
        }}
        key={index}
        control={<Checkbox sx={{ padding: 0 }} checked={checked} name={`${index}`} onChange={onClickMemo} />}
        label='Memo question'
      />
      <ButtonMemoNext disabled={indexMemo.length === 0 || index >= indexMemo[indexMemo.length - 1]} index={index} setIndex={setIndex} indexMemo={indexMemo} />
    </Box>
  );
}

export default MemoQuestion;
