import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import MenuMobile from './components/MenuMobile';
import AvatarSetting from './components/AvatarSetting';
import MenuDesktop from './components/MenuDesktop';
import { useSelector } from 'react-redux';
import { selectDomainByKey } from 'redux/domain/domainSelectors';
import AuthActions from './components/AuthActions';

function Menu() {
  const user = useSelector(selectDomainByKey('user'));
  return (
    <AppBar position='static'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <MenuMobile />
          <MenuDesktop />
          {user ? <AvatarSetting /> : <AuthActions />}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Menu;
