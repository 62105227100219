import { Box } from '@mui/material'
import React from 'react'

function HomePage() {
  return (
    <Box sx={{ textAlign: 'center' }} data-testid="home-page">
      Welcome to Certification
    </Box>
  )
}

export default HomePage
