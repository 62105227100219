import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import { nbQuestion } from 'constants/java11';

function ButtonIncorrectNext({ index, setIndex, disabled, incorrectQuestions }) {
  const onClickNext = useCallback(() => {
    for (let i = index + 1; i < nbQuestion; i++) {
      const nextIndex = incorrectQuestions?.find((j) => j - 1 > index);
      if (nextIndex) {
        setIndex(nextIndex - 1);
        break;
      }
    }
  }, [incorrectQuestions, index, setIndex]);
  return (
    <Button disabled={disabled} variant='outlined' color='error' onClick={onClickNext}>
      Next Incorrect
    </Button>
  );
}

export default ButtonIncorrectNext;
