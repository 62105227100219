import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { wrapAlphabetByTag } from 'utils/questions';

function CreateQuestionPage(props) {
  const [question, setQuestion] = useState('');
  const [jsonOutput, setJsonOutput] = useState('');
  const [optionMultiline, setOptionMultiline] = useState(false);
  const [language, setLanguage] = useState('Java');
  const [certiType, setCertiType] = useState('OCA');
  const [category, setCategory] = useState('Data Types');

  const onChangeQuestion = useCallback(
    (e) => {
      setQuestion(e.target.value);
      const value = e.target.value;
      const splitKey = optionMultiline ? '\n\n\n' : '\n\n';
      const listValue = value.split(splitKey).map((line) => line.trim());

      const q = {};
      q.id = +listValue[0];
      q.language = language;
      q.certiType = certiType;
      q.category = category;

      q.label = listValue[1];

      q.content = listValue[2];

      const optionSplit = optionMultiline ? '\n\n' : '\n';
      const options = listValue[3]?.split(optionSplit)?.map((line) => line?.trim());
      q.options = options?.map((option) => {
        const optionList = option?.split('.');
        const label = optionList?.slice(1)?.join('.')?.trim();
        return {
          code: optionList[0]?.trim(),
          label: label,
        };
      });

      q.answers = listValue[4]?.split(',')?.map((answer) => answer?.trim());

      const answersDetail = `${listValue[4]}${listValue[5]}`?.replaceAll('\n', ' ')?.replaceAll('. A ', '. a ')?.split('.')?.join('.\n');
      q.answersDetail = wrapAlphabetByTag(answersDetail);

      setJsonOutput(JSON.stringify(q));
    },
    [setQuestion, optionMultiline, language, certiType, category]
  );
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center', width: { md: '100%' } }}>
        <TextField label='Language' variant='outlined' fullWidth value={language} onChange={(e) => setLanguage(e.target.value)} />
        <TextField label='Certification' variant='outlined' fullWidth value={certiType} onChange={(e) => setCertiType(e.target.value)} />
        <TextField label='Category' variant='outlined' fullWidth value={category} onChange={(e) => setCategory(e.target.value)} />
      </Box>
      <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center', width: { md: '100%' } }}>
        <TextField label='Question' variant='outlined' multiline rows={20} fullWidth value={question} onChange={onChangeQuestion} />
        <TextField label='Json Output' variant='outlined' multiline rows={20} fullWidth value={jsonOutput} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={optionMultiline}
              onChange={(e) => {
                setOptionMultiline(e.target.checked);
              }}
            />
          }
          label='Option multiline - Split key by 2 lines'
        />
      </Box>
    </Box>
  );
}

export default CreateQuestionPage;
