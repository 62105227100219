import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuMui from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { signOut } from 'firebase/auth';
import { auth } from 'config/firebase.config';
import { useDispatch } from 'react-redux';
import { domainActions } from 'redux/domain/domainSlices';
import { useNavigate } from 'react-router-dom';
import paths from 'router/paths';

const settings = ['Profile', 'Account', 'Dashboard'];

function AvatarSetting() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = useCallback(() => {
    signOut(auth)
      .then(() => {
        dispatch(
          domainActions.resetDomain({
            key: 'user',
          })
        );
        navigate(paths.home);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch, navigate]);

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title='Open settings'>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar />
        </IconButton>
      </Tooltip>
      <MenuMui
        sx={{ mt: '45px' }}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}>
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={handleCloseUserMenu}>
            <Typography textAlign='center'>{setting}</Typography>
          </MenuItem>
        ))}
        <MenuItem onClick={logout}>
          <Typography textAlign='center'>Logout</Typography>
        </MenuItem>
      </MenuMui>
    </Box>
  );
}

export default AvatarSetting;
