import React from 'react';
import CheckboxesGroup from 'components/CheckboxesGroup';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectRefByKey } from 'redux/reference/refSelectors';
import refKeys from 'redux/reference/refKeys';
import { getCorrectAnswer } from 'utils/questions';

function Question({ question }) {
  const isSubmitted = useSelector(selectRefByKey(refKeys.isSubmitted));
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CheckboxesGroup id={question?.id} label={question?.label} content={question?.content} options={question?.options} />
      {isSubmitted && (
        <Box sx={{ color: question?.isCorrect ? 'green' : 'red', whiteSpace: 'pre-line' }}>
          {getCorrectAnswer(question?.options)}
          {question?.answersDetail}
        </Box>
      )}
    </Box>
  );
}

export default Question;
