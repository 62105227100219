import { Button } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

function ButtonPrev({ setIndex, disabled }) {
  const onClickPrev = useCallback(() => {
    if (!disabled) setIndex((index) => index - 1);
  }, [setIndex, disabled]);

  const onKeyUp = useCallback(
    (e) => {
      if (e?.keyCode === 37) {
        onClickPrev();
      }
    },
    [onClickPrev]
  );

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);

  return (
    <Button disabled={disabled} variant='outlined' onClick={onClickPrev}>
      Prev
    </Button>
  );
}

export default ButtonPrev;
