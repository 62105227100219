import { Box } from '@mui/material';
import { getAlphabetLabel } from './data';

export const getCorrectAnswer = (question) => {
  return question?.options?.filter((option) => option?.correct)?.map((option) => option?.code);
};

export const replaceCodeByDisplayCode = (question) => {
  let result = question?.answersDetail;
  for (let i = 0; i < question?.options?.length; i++) {
    result = result?.replaceAll(`#${question?.options[i]?.code}#`, `"${question?.options[i]?.displayCode}"`);
  }
  return result;
};

export const isAnswerCorrect = (question) => {
  return question?.options?.every((option) => option?.correct === option?.checked);
};

export const getNumberCorrectAnswer = (questions = {}) => {
  return Object.values(questions)?.filter((question) => question?.isCorrect)?.length;
};

export const getIncorrectAnswers = (questions) => {
  return Object.values(questions)
    ?.filter((question) => !question?.isCorrect)
    ?.map((question) => question?.order)
    ?.sort((a, b) => a - b);
};

export const getResultText = (questions) => {
  const nbCorrectAnswer = getNumberCorrectAnswer(questions);
  const nbQuestion = Object.keys(questions)?.length;
  const percent = Math.floor((nbCorrectAnswer / nbQuestion) * 100);
  return <Box sx={{ color: percent >= 68 ? 'green' : 'red' }}>{`${nbCorrectAnswer} / ${nbQuestion} (${percent}%)`}</Box>;
};

export const wrapAlphabetByTag = (text) => {
  return text
    .replace(/ [A-Z] /g, (match) => ` #${match?.trim()}# `)
    .replace(/[A-Z],/g, (match) => `#${match.substring(0, match.length - 1)?.trim()}#,`)
    .replace(/[A-Z]\./g, (match) => `#${match.substring(0, match.length - 1)?.trim()}#.`);
};

export const formatQuestion = ({ question, order }) => {
  const temp = {
    ...question,
    order,
    options: [...question?.options]
      .sort(() => Math.random() - 0.5)
      ?.map((option, index) => ({
        ...option,
        checked: false,
        displayCode: getAlphabetLabel(index),
        correct: question?.answers?.includes(option?.code),
      })),
  };

  return {
    ...temp,
    answersDetail: replaceCodeByDisplayCode(temp),
  };
};
