import { combineReducers, configureStore } from '@reduxjs/toolkit';
import domainSlices from './domain/domainSlices';
import refSlices from './reference/refSlices';

const rootReducer = combineReducers({
  domain: domainSlices,
  ref: refSlices,
});

const store = configureStore({
  reducer: rootReducer,
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export default store;
