import { createSlice } from '@reduxjs/toolkit';
import { checkOption, checkQuestions, setQuestions, updateQuestion } from './reducers/questionReducers';

const initialState = {};

const domainSlices = createSlice({
  name: 'domain',
  initialState,
  reducers: {
    setDomain: (state, { payload }) => {
      state[payload.key] = payload.value;
    },
    resetDomain: (state, { payload }) => {
      if (!payload) {
        state = initialState;
      } else {
        state[payload.key] = initialState[payload.key];
      }
    },
    setQuestions,
    updateQuestion,
    checkOption,
    checkQuestions,
  },
  extraReducers: (builder) => {},
});

export const domainActions = domainSlices.actions;

export default domainSlices.reducer;
