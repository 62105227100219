import React, { useCallback, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from 'features/Copyright';
import { Alert } from '@mui/material';
import { isEmpty } from 'lodash';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from 'config/firebase.config';
import paths from 'router/paths';

export default function ForgotPasswordPage() {
  const [alert, setAlert] = useState(null);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');

    if (!email || isEmpty(email)) return setAlert({ message: 'Email is required', severity: 'error' });

    sendPasswordResetEmail(auth, data.get('email'))
      .then(() => {
        setAlert({ message: 'Email sent', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setAlert({ message: error.message, severity: 'error' });
      });
  }, []);

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Forgot password
        </Typography>
        {alert && <Alert severity={alert?.severity}>{alert?.message}</Alert>}
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField margin='normal' required fullWidth id='email' label='Email Address' name='email' autoComplete='email' autoFocus />
          <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
            Reset password
          </Button>
          <Grid container justifyContent={'center'} gap={1}>
            <Grid item>
              <Link href={paths.login} variant='body2'>
                Already have an account? Log in
              </Link>
            </Grid>
            <Grid item>
              <Link href={paths.signup} variant='body2'>
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
