import paths from 'router/paths'

const navItems = {
  home: {
    text: 'Home',
    path: paths.home,
  },
  javaOcp: {
    text: 'Java 11 OCP',
    path: paths.javaOcp,
  },
  createQuestion: {
    text: 'Create Question',
    path: paths.createQuestion,
  },
}

export const navItemsArray = Object.entries(navItems)

export default navItems
