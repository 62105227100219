import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { navItemsArray } from '../navItems'
import MenuIcon from '@mui/icons-material/Menu'
import MenuMui from '@mui/material/Menu'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router-dom'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'
import AppName from './AppName'

function MenuMobile() {
  const [anchorElNav, setAnchorElNav] = useState()
  const navigate = useNavigate()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const onClickMenuItem = (to) => {
    navigate(to)
    setAnchorElNav(null)
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <MenuMui
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          {navItemsArray.map((item) => (
            <MenuItem
              key={item?.[0]}
              data-testid={`menu-mobile-${item?.[0]}`}
              onClick={() => onClickMenuItem(item?.[1].path)}
            >
              <Typography textAlign="center">{item?.[1].text}</Typography>
            </MenuItem>
          ))}
        </MenuMui>
      </Box>
      <WorkspacePremiumIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
      <AppName variant="mobile" />
    </>
  )
}

export default MenuMobile
