import { isAnswerCorrect } from 'utils/questions';
import domainKeys from '../domainKeys';

export const updateQuestion = (state, action) => {
  const { payload } = action;
  state[domainKeys.questions][payload?.id] = payload;
};

export const setQuestions = (state, action) => {
  const { payload } = action;
  state[domainKeys.questions] = payload;
};

export const checkOption = (state, action) => {
  const { payload } = action;
  const { id, index, checked } = payload;
  state[domainKeys.questions][id].options[index].checked = checked;
};

export const checkQuestions = (state) => {
  const questions = Object.entries(state[domainKeys.questions]);
  state[domainKeys.questions] = Object.fromEntries(
    questions.map((question) => {
      const [id, questionDetail] = question;
      return [
        id,
        {
          ...questionDetail,
          isCorrect: isAnswerCorrect(questionDetail),
        },
      ];
    })
  );
};
