import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from 'pages/HomePage';
import Java11Page from 'pages/Java11Page';
import Menu from 'features/Menu/Menu';
import { Box, Toolbar } from '@mui/material';
import CreateQuestionPage from 'pages/CreateQuestionPage';
import LoginPage from 'pages/auth/LoginPage';
import SignUpPage from 'pages/auth/SignUpPage';
import AuthRoute from './AuthRoute';
import paths from './paths';
import ForgotPasswordPage from 'pages/auth/ForgotPasswordPage';
import ResetPasswordPage from 'pages/auth/ResetPasswordPage';

function App() {
  return (
    <BrowserRouter>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Menu />
        <Box component='main' sx={{ p: 3 }}>
          <Toolbar />
          <Routes>
            <Route path={paths.home} element={<HomePage />} />
            <Route path={paths.login} element={<LoginPage />} />
            <Route path={paths.signup} element={<SignUpPage />} />
            <Route path={paths.forgotPassword} element={<ForgotPasswordPage />} />
            <Route path={paths.resetPassword} element={<ResetPasswordPage />} />
            <Route
              path={paths.javaOcp}
              element={
                <AuthRoute>
                  <Java11Page />
                </AuthRoute>
              }
            />
            <Route
              path={paths.createQuestion}
              element={
                <AuthRoute>
                  <CreateQuestionPage />
                </AuthRoute>
              }
            />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  );
}

export default App;
