import React from 'react'
import { useNavigate } from 'react-router-dom'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'
import AppName from './AppName'
import { Box, Button } from '@mui/material'
import { navItemsArray } from '../navItems'

function MenuDesktop() {
  const navigate = useNavigate()

  return (
    <>
      <WorkspacePremiumIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
      <AppName />
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        {navItemsArray.map((item) => (
          <Button
            key={item[0]}
            data-testid={`menu-desktop-${item[0]}`}
            onClick={() => navigate(item?.[1].path)}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            {item?.[1].text}
          </Button>
        ))}
      </Box>
    </>
  )
}

export default MenuDesktop
