import React, { useCallback, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from 'features/Copyright';
import { Alert } from '@mui/material';
import { isEmpty } from 'lodash';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from 'config/firebase.config';
import { useNavigate, useSearchParams } from 'react-router-dom';
import paths from 'router/paths';

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');

  const [alert, setAlert] = useState(null);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const password = data.get('password');

      if (!password || isEmpty(password)) return setAlert({ message: 'Password are required' });

      confirmPasswordReset(auth, oobCode, password)
        .then(() => {
          setAlert({ message: 'Reset password successfully', severity: 'success' });
          navigate(paths.login);
        })
        .catch((error) => {
          console.log(error);
          setAlert({ message: error.message, severity: 'error' });
        });
    },
    [navigate, oobCode]
  );

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Log in
        </Typography>
        {alert && <Alert severity={alert?.severity}>{alert?.message}</Alert>}
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField margin='normal' required fullWidth name='password' label='Password' type='password' id='password' autoComplete='current-password' />
          <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
            Reset Password
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
