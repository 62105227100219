import { Button } from '@mui/material';
import React, { useCallback } from 'react';

function ButtonIncorrectPrev({ index, setIndex, disabled, incorrectQuestions }) {
  const onClickPrev = useCallback(() => {
    const sortDescIndexMemo = [...incorrectQuestions]?.sort((a, b) => b - a);
    for (let i = index - 1; i >= 0; i--) {
      const prevIndex = sortDescIndexMemo?.find((j) => j - 1 < index);
      if (prevIndex) {
        setIndex(prevIndex - 1);
        break;
      }
    }
  }, [incorrectQuestions, index, setIndex]);
  return (
    <Button disabled={disabled} variant='outlined' color='error' onClick={onClickPrev}>
      Prev Incorrect
    </Button>
  );
}

export default ButtonIncorrectPrev;
