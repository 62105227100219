import { ArrowBack } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useCallback } from 'react';

function ButtonMemoPrev({ index, setIndex, disabled, indexMemo }) {
  const onClickPrev = useCallback(() => {
    const sortDescIndexMemo = [...indexMemo]?.sort((a, b) => b - a);
    for (let i = index - 1; i >= 0; i--) {
      const prevIndex = sortDescIndexMemo?.find((j) => j < index);
      if (prevIndex) {
        setIndex(prevIndex);
        break;
      }
    }
  }, [indexMemo, index, setIndex]);
  return (
    <Button disabled={disabled} color='warning' onClick={onClickPrev}>
      <ArrowBack />
    </Button>
  );
}

export default ButtonMemoPrev;
