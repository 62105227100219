import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refActions } from 'redux/reference/refSlices';
import { domainActions } from 'redux/domain/domainSlices';
import { selectRefByKey } from 'redux/reference/refSelectors';
import refKeys from 'redux/reference/refKeys';

function ButtonSubmit(props) {
  const dispatch = useDispatch();
  const isSubmitted = useSelector(selectRefByKey(refKeys.isSubmitted));

  const onClickSubmit = useCallback(() => {
    dispatch(domainActions.checkQuestions());
    dispatch(refActions.submit());
  }, [dispatch]);
  return (
    <Button variant='contained' onClick={onClickSubmit} disabled={isSubmitted}>
      Submit
    </Button>
  );
}

export default ButtonSubmit;
