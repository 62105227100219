import React from 'react'
import Typography from '@mui/material/Typography'

function AppName({ variant }) {
  return (
    <Typography
      variant={variant === 'mobile' ? 'h6' : 'h5'}
      noWrap
      component="a"
      href="#app-bar-with-responsive-menu"
      sx={{
        mr: 2,
        display: variant === 'mobile' ? { xs: 'flex', md: 'none' } : { xs: 'none', md: 'flex' },
        fontFamily: 'monospace',
        fontWeight: 700,
        letterSpacing: '.3rem',
        color: 'inherit',
        textDecoration: 'none',
      }}
      datatype="app-name"
    >
      CERTI
    </Typography>
  )
}

export default AppName
