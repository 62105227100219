import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import domainKeys from 'redux/domain/domainKeys';
import { selectDomainByKey } from 'redux/domain/domainSelectors';
import { getNumberCorrectAnswer } from 'utils/questions';

function Result({ incorrectQuestions }) {
  const selectedQuestions = useSelector(selectDomainByKey(domainKeys.questions));

  const nbCorrectAnswer = getNumberCorrectAnswer(selectedQuestions);
  const nbQuestion = Object.keys(selectedQuestions ?? {})?.length;
  const percent = Math.floor((nbCorrectAnswer / nbQuestion) * 100);
  return (
    <Box sx={{ display: 'flex', gap: '0', flexDirection: 'column', alignItems: 'center', fontSize: '1.2rem' }}>
      <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <div>Result: </div>
        <Box sx={{ color: percent >= 68 ? 'green' : 'red' }}>
          <div>{`${nbCorrectAnswer} / ${nbQuestion} (${percent}%)`}</div>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <div>Incorrect answer: </div>
        <div>{incorrectQuestions?.join(', ')} </div>
      </Box>
    </Box>
  );
}

export default Result;
