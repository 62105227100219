import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import refKeys from 'redux/reference/refKeys';
import { selectRefByKey } from 'redux/reference/refSelectors';
import { maxTime, warningTime, warningTime2 } from 'constants/java11';
import { domainActions } from 'redux/domain/domainSlices';
import { refActions } from 'redux/reference/refSlices';
import { getTimeString } from 'utils/date';

const getColor = (time) => {
  if (time > warningTime2) {
    return 'red';
  }
  if (time > warningTime) {
    return 'orange';
  }
  return 'black';
};

function Timer() {
  const dispatch = useDispatch();
  const isSubmitted = useSelector(selectRefByKey(refKeys.isSubmitted));
  const interval = useRef(null);

  const start = useRef(Date.now());
  const [time, setTime] = useState(start.current);

  useEffect(() => {
    interval.current = setInterval(() => {
      setTime(Date.now());
    }, 1000);
    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (isSubmitted) {
      clearInterval(interval.current);
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (time - start.current >= maxTime && !isSubmitted) {
      dispatch(domainActions.checkQuestions());
      dispatch(refActions.submit());
    }
  }, [dispatch, time, isSubmitted]);

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box sx={{ color: getColor(time - start.current) }}>{getTimeString(time - start.current)}</Box>
      <Box>/</Box>
      <Box>{getTimeString(maxTime)}</Box>
    </Box>
  );
}

export default Timer;
