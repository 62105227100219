export const getAlphabetLabel = (index) => {
  return String.fromCharCode(65 + index);
};

export const randomArrayBetween = (min, max, length) => {
  const array = new Set();
  while (array.size < length) {
    array.add(Math.floor(Math.random() * (max - min + 1) + min));
  }
  return Array.from(array);
};

export const parseDoubleQuotes = (string) => {
  return string?.replace(/"/g, '"');
};

export const removeWhiteSpaces = (string) => {
  return string?.replace(/\s/g, '');
};
