import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { selectDomainByKey } from 'redux/domain/domainSelectors';

function AuthRoute({ children }) {
  const user = useSelector(selectDomainByKey('user'));
  if (user) return children;

  return <Box sx={{ textAlign: 'center' }}>Oops! You are not logged in. Please login to continue.</Box>;
}

export default AuthRoute;
