import React, { useEffect, useMemo, useState } from 'react';
import Question from 'features/Question';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { domainActions } from 'redux/domain/domainSlices';
import { selectDomainByKey } from 'redux/domain/domainSelectors';
import domainKeys from 'redux/domain/domainKeys';
import ButtonNext from './buttons/ButtonNext';
import ButtonPrev from './buttons/ButtonPrev';
import ButtonSubmit from './buttons/ButtonSubmit';
import { refActions } from 'redux/reference/refSlices';
import refKeys from 'redux/reference/refKeys';
import { getIncorrectAnswers } from 'utils/questions';
import { selectRefByKey } from 'redux/reference/refSelectors';
import Timer from './help/Timer';
import ButtonIncorrectPrev from './buttons/ButtonIncorrectPrev';
import ButtonIncorrectNext from './buttons/ButtonIncorrectNext';
import MemoQuestion from './MemoQuestion/MemoQuestion';
import Result from './help/Result';
import useGetQuestions from './useGetQuestions';

function Java11Page() {
  const dispatch = useDispatch();
  const selectedQuestions = useSelector(selectDomainByKey(domainKeys.questions));
  const isSubmitted = useSelector(selectRefByKey(refKeys.isSubmitted));

  const [index, setIndex] = useState(0);

  const { idQuestions } = useGetQuestions();

  useEffect(() => {
    return () => {
      dispatch(domainActions.resetDomain({ key: domainKeys.questions }));
      dispatch(refActions.resetRef({ key: refKeys.isSubmitted }));
    };
  }, [dispatch]);

  const incorrectQuestions = useMemo(() => {
    if (isSubmitted) {
      return getIncorrectAnswers(selectedQuestions);
    }
    return [];
  }, [selectedQuestions, isSubmitted]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1, alignItems: 'center', width: { md: '50%' } }}>
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Timer />
          <div>{`Question ${index + 1}/${idQuestions?.length}`}</div>
        </Box>
        <MemoQuestion index={index} setIndex={setIndex} />
        {!!selectedQuestions && Object.keys(selectedQuestions)?.length > 0 && (
          <Question
            question={{
              ...selectedQuestions[idQuestions[index]],
              label: `${index + 1}. ${selectedQuestions[idQuestions[index]]?.label}`,
            }}
          />
        )}
        <Box sx={{ display: 'flex', columnGap: 5 }}>
          <ButtonPrev setIndex={setIndex} disabled={index <= 0} />
          <ButtonNext setIndex={setIndex} disabled={index >= idQuestions?.length - 1} />
        </Box>
        <ButtonSubmit />
        {isSubmitted && (
          <>
            <Result incorrectQuestions={incorrectQuestions} />

            <Box sx={{ display: 'flex', columnGap: 5 }}>
              <ButtonIncorrectPrev index={index} incorrectQuestions={incorrectQuestions} setIndex={setIndex} disabled={index <= incorrectQuestions?.[0] - 1} />
              <ButtonIncorrectNext
                index={index}
                setIndex={setIndex}
                incorrectQuestions={incorrectQuestions}
                disabled={index >= incorrectQuestions?.[incorrectQuestions?.length - 1] - 1}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default Java11Page;
