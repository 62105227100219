import { Avatar, Box, Button, IconButton, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MenuMui from '@mui/material/Menu'
import paths from 'router/paths'
import useResponsive from 'hooks/useResponsive'

const auths = [
  {
    id: 'login',
    text: 'Log in',
    path: paths.login,
    color: 'info',
  },
  {
    id: 'signup',
    text: 'Sign up',
    path: paths.signup,
    color: 'secondary',
  },
]

function AuthActions() {
  const navigate = useNavigate()
  const { isMobile } = useResponsive()
  const [anchorEl, setAnchorEl] = useState()

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const onClickMenuItem = (to) => {
    navigate(to)
    setAnchorEl(null)
  }

  if (isMobile)
    return (
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenMenu} sx={{ p: 0 }}>
            <Avatar />
          </IconButton>
        </Tooltip>
        <MenuMui
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {auths.map((auth) => (
            <MenuItem key={auth.id} data-testid={`menu-mobile-${auth.id}`} onClick={() => onClickMenuItem(auth.path)}>
              <Typography textAlign="center">{auth.text}</Typography>
            </MenuItem>
          ))}
        </MenuMui>
      </Box>
    )

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {auths.map((auth) => (
        <Button
          key={auth.id}
          data-testid={`menu-desktop-${auth.id}`}
          onClick={() => navigate(auth.path)}
          variant="contained"
          color={auth.color}
        >
          {auth.text}
        </Button>
      ))}
    </Box>
  )
}

export default AuthActions
