import { createSlice } from '@reduxjs/toolkit';
import { submit } from './reducers/refReducers';

const initialState = {};

const refSlices = createSlice({
  name: 'ref',
  initialState,
  reducers: {
    /**
     * @param {any} state
     * @param {{
     * key: keyof typeof refKeys;
     * data: any;
     * }} payload
     * @returns
     * @example
     * dispatch(setRef({ key: refKeys.isSubmitted, data: true }));
     */
    setRef: (state, { payload }) => {
      state[payload.key] = payload.data;
    },
    resetRef: (state, { payload }) => {
      if (payload?.key) {
        state[payload.key] = initialState[payload.key];
      } else {
        state = initialState;
      }
    },
    submit,
  },
  extraReducers: (builder) => {},
});

export const refActions = refSlices.actions;

export default refSlices.reducer;
