import './main.css';
import { ThemeProvider, createTheme } from '@mui/material';
import React from 'react';

const defaultTheme = createTheme();

function ThemeWrapper({ children }) {
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
}

export default ThemeWrapper;
