import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { domainActions } from 'redux/domain/domainSlices';
import { getDocs, query, where, getCountFromServer } from 'firebase/firestore';
import { javaQuestionsCollection } from 'config/firebase.config';
import { formatQuestion } from 'utils/questions';
import { randomArrayBetween } from 'utils/data';
import { nbQuestion } from 'constants/java11';

const useGetQuestions = () => {
  const dispatch = useDispatch();
  const [idQuestions, setIdQuestions] = useState([]);

  useEffect(() => {
    const getCount = async () => {
      const querySnapshot = await getCountFromServer(javaQuestionsCollection);
      const nbTotalQuestions = querySnapshot.data().count;
      const ids = randomArrayBetween(1, nbTotalQuestions, nbQuestion);
      return { ids };
    };

    const getData = async () => {
      const { ids } = await getCount();
      const q = query(javaQuestionsCollection, where('id', 'in', ids));
      const querySnapshot = await getDocs(q);
      const questionsObject = {};
      for (let i = 0; i < querySnapshot.docs.length; i++) {
        const question = querySnapshot.docs[i].data();
        const id = question?.id;
        questionsObject[id] = formatQuestion({ question, order: i + 1 });
        ids[i] = id;
      }

      dispatch(domainActions.setQuestions(questionsObject));

      setIdQuestions(ids);
    };

    getData();
  }, [dispatch]);

  return { idQuestions };
};

export default useGetQuestions;
