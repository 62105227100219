const paths = {
  home: '/',
  login: '/login',
  signup: '/signup',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  javaOcp: '/java-ocp',
  createQuestion: '/create-question',
};

export default paths;
