import React, { useCallback, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Copyright from 'features/Copyright'
import { Alert } from '@mui/material'
import { isEmpty } from 'lodash'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from 'config/firebase.config'
import { useDispatch } from 'react-redux'
import { domainActions } from 'redux/domain/domainSlices'
import { useNavigate } from 'react-router-dom'
import paths from 'router/paths'

export default function SignInPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [error, setError] = useState(null)

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()
      const data = new FormData(event.currentTarget)
      const email = data.get('email')
      const password = data.get('password')

      if (!email || !password || isEmpty(email) || isEmpty(password))
        return setError({ message: 'Email and password are required' })

      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          dispatch(
            domainActions.setDomain({
              key: 'user',
              value: userCredential?.user,
            }),
          )
          navigate(paths.home)
        })
        .catch((error) => {
          console.log(error)
          setError(error)
        })
    },
    [dispatch, navigate],
  )

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        {error && <Alert severity="error">{error?.message}</Alert>}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            data-testid="email"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            data-testid="password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
            data-testid="remember-me"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} data-testid="submit-login">
            Log In
          </Button>
          <Grid container justifyContent={'space-between'} gap={1}>
            <Grid item>
              <Link href={paths.forgotPassword} variant="body2" data-testid="forgot-password">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href={paths.signup} variant="body2" data-testid="sign-up">
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  )
}
