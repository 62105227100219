import React, { useCallback, useMemo } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch } from 'react-redux';
import { domainActions } from 'redux/domain/domainSlices';
import { Box } from '@mui/material';

function CheckboxesGroup({ id, label, content, options }) {
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (event, index) => {
      dispatch(domainActions.checkOption({ id, index, checked: event.target.checked }));
    },
    [dispatch, id]
  );

  const renderContent = useMemo(() => {
    if (content?.startsWith('data:image')) {
      return <img src={content} alt={content} />;
    }
    return content;
  }, [content]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
      <FormLabel sx={{ fontSize: '1.2rem', color: 'rgba(0, 0, 0, 0.87)' }}>{label}</FormLabel>
      <FormLabel sx={{ whiteSpace: 'pre-line', paddingLeft: 2 }}>{renderContent}</FormLabel>
      <Box sx={{ gap: 1, paddingLeft: 1, display: 'flex', flexDirection: 'column' }}>
        {options?.map((option, index) => (
          <FormControlLabel
            sx={{
              alignItems: 'flex-start',
              gap: 1,
            }}
            key={index}
            control={<Checkbox sx={{ padding: 0 }} checked={option?.checked ?? false} name={`${index}`} onChange={(e) => handleChange(e, index)} />}
            label={<Box sx={{ whiteSpace: 'pre-line' }}>{`${option?.displayCode}. ${option?.label}`}</Box>}
          />
        ))}
      </Box>
    </Box>
  );
}

export default CheckboxesGroup;
