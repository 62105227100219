import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import { nbQuestion } from 'constants/java11';
import { ArrowForward } from '@mui/icons-material';

function ButtonMemoNext({ index, setIndex, disabled, indexMemo }) {
  const onClickNext = useCallback(() => {
    for (let i = index + 1; i < nbQuestion; i++) {
      const nextIndex = indexMemo?.find((j) => j > index);
      if (nextIndex) {
        setIndex(nextIndex);
        break;
      }
    }
  }, [indexMemo, index, setIndex]);
  return (
    <Button disabled={disabled} color='warning' onClick={onClickNext}>
      <ArrowForward />
    </Button>
  );
}

export default ButtonMemoNext;
