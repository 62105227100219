import { Button } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

function ButtonNext({ setIndex, disabled }) {
  const onClickNext = useCallback(() => {
    if (!disabled) setIndex((index) => index + 1);
  }, [setIndex, disabled]);

  const onKeyUp = useCallback(
    (e) => {
      if (e?.keyCode === 39) {
        onClickNext();
      }
    },
    [onClickNext]
  );

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);

  return (
    <Button disabled={disabled} variant='outlined' onClick={onClickNext}>
      Next
    </Button>
  );
}

export default ButtonNext;
