import React, { useCallback, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Copyright from 'features/Copyright'
import { auth } from 'config/firebase.config'
import { useDispatch } from 'react-redux'
import { domainActions } from 'redux/domain/domainSlices'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { Alert } from '@mui/material'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import paths from 'router/paths'

function SignUpPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [error, setError] = useState(null)

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()
      const data = new FormData(event.currentTarget)

      const email = data.get('email')
      const password = data.get('password')

      if (!email || !password || isEmpty(email) || isEmpty(password))
        return setError({ message: 'Email and password are required' })

      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          dispatch(
            domainActions.setDomain({
              key: 'user',
              value: userCredential?.user,
            }),
          )
          navigate(paths.home)
        })
        .catch((error) => {
          console.log(error)
          setError(error)
        })
    },
    [dispatch, navigate],
  )

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Avatar sx={{ bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        {error && <Alert severity="error">{error?.message}</Alert>}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                data-testid="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                data-testid="password"
              />
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} data-testid="submit-signup">
            Sign Up
          </Button>
          <Grid container justifyContent="center">
            <Grid item>
              <Link href={paths.login} variant="body2" data-testid="login">
                Already have an account? Log in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  )
}

export default SignUpPage
